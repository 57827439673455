import { Injectable } from "@angular/core";
import { PouchAllDocsResult } from "@orion2/models/couch.models";
import { separatorApplic } from "@orion2/utils/constants.utils";
import { getRanges } from "@orion2/utils/functions.utils";
import { ApplicabilityService, CriteriaItem } from "@viewer/core";

export interface ApplicObject {
  serialNo: string[];
  versions: string[];
}
export interface ApplicCondition {
  condition: string[];
  serialNo: string;
}

export interface SubApplicabilities {
  data: {
    [subApplic: string]: {
      criterias: string[];
    };
  };
}

export interface DbCriterias {
  data: {
    [criteria: string]: CriteriaItem;
  };
}

export interface Applicabilities {
  data: {
    [applicMD5: string]: {
      subapplicabilities: string[];
    };
  };
}

@Injectable()
export class CiraService {
  ciraXml: Document;
  applicPropertyValuesMap: Map<string, string | string[]>;
  associatedSb: Map<string, string>;
  subapplicabilities: SubApplicabilities;
  dbCriterias: DbCriterias;
  applicabilities: Applicabilities;

  constructor(private applicService: ApplicabilityService) {}

  /**
   * Store applicabilityMD5
   *
   * @param applicMD5 exemple : fe5a461e5bbeb630029bce91b660ab6e
   */
  public getApplicListFromMD5(applicMD5: string): Promise<ApplicObject> {
    const criterias = new Set<string>();
    return this.applicService.applicableMD5Prom.then((allDocs: PouchAllDocsResult) => {
      this.subapplicabilities ||= allDocs.rows.find(row => row._id === "subapplicabilities.json");
      this.dbCriterias ||= allDocs.rows.find(row => row._id === "criterias.json");

      for (const docItem of allDocs.rows) {
        if (docItem.data && Array.from(docItem.data).includes(applicMD5)) {
          criterias.add(docItem._id);
        }
      }

      const criteriasObject: ApplicObject = { versions: [], serialNo: [] };
      this.fillCriteriaList(criterias, criteriasObject);
      return criteriasObject;
    });
  }

  // return a string array with applicability condition from applicabilities, subapplicabilities and criterias.json
  public getConditionalApplic(applicMD5: string): Promise<string[]> {
    return this.applicService.applicableMD5Prom.then((allDocs: PouchAllDocsResult) => {
      const applics: ApplicCondition[] = [];
      this.applicabilities ||= allDocs.rows.find(row => row._id === "applicabilities.json");
      this.subapplicabilities ||= allDocs.rows.find(row => row._id === "subapplicabilities.json");
      this.dbCriterias ||= allDocs.rows.find(row => row._id === "criterias.json");

      if (!this.applicabilities.data[applicMD5]) {
        return [];
      }

      const subApplics = this.applicabilities.data[applicMD5].subapplicabilities;
      subApplics.forEach((subApplic: string) => {
        // we need only serial numbers conditions from criterias list
        const applicCondition: ApplicCondition = {
          serialNo: "",
          condition: []
        };
        const criterias = this.subapplicabilities.data[subApplic].criterias;
        criterias.forEach((criteria: string) => {
          const data = this.dbCriterias.data[criteria];
          if (data.type === "ACONDITIONAL" && data.name === "serialno") {
            if (data.from === data.to) {
              applicCondition.serialNo = data.from;
            } else {
              applicCondition.serialNo = `${data.from}-${data.to}`;
            }
          }
          if (data.type === "CONDITIONAL") {
            applicCondition.condition.push(`${data.value} ${data.name}`);
          }
        });
        if (applicCondition.condition.length || applicCondition.serialNo) {
          // we want to display condition in alphabetic order
          applicCondition.condition.sort((a, b) => a.localeCompare(b));
          applics.push(applicCondition);
        }
      });
      // now we want to group the serial numbers by conditions
      // exemple [{serialNo: "1010", condition: ""},{serialNo: "1011", condition: ""},{serialNo: "1009", condition: "POST SB1234"}]
      // become => {"" => [1010,1011], "POST SB1234" => "1009"}
      const conditionMap = new Map<string, string[]>();
      applics.forEach((applicCondition: ApplicCondition) => {
        const conditionstring = applicCondition.condition.join(" AND ");
        const serialNoString = applicCondition.serialNo;
        if (!conditionMap[conditionstring]) {
          conditionMap[conditionstring] = [];
        }
        conditionMap[conditionstring].push(serialNoString);
      });
      // we transform the map to an string array like :
      // [ "SN1, SN2, condition1 condition2", "SN3, condition3"]
      const conditionList = Object.keys(conditionMap);
      // We want to display the condition PRE before POST condition but number before
      conditionList.sort(
        (a: string, b: string) =>
          +/[A-Za-z]/.test(a) - +/[A-Za-z]/.test(b) ||
          a[0].localeCompare(b[0]) ||
          b.localeCompare(a)
      );

      return conditionList.map((condition: string) => {
        // we want to display serial number in arithmetic order
        conditionMap[condition].sort((a, b) => a - b);
        // we transform serial number to interval of serial numbers
        conditionMap[condition] = getRanges(conditionMap[condition]);

        /**
         * SPEC: POST SB may to not have a liked serial number (the value inside conditionMap
         * will be [""]) in this use case we will display only the condition.
         */
        const condStr =
          condition && conditionMap[condition][0] ? `${separatorApplic}${condition}` : condition;
        return conditionMap[condition].join(separatorApplic) + condStr;
      });
    });
  }

  private fillCriteriaList(criteriasMap: Set<string>, criteriasObject: ApplicObject): void {
    if (!this.dbCriterias?.data) {
      return;
    }

    Object.keys(this.subapplicabilities.data)
      .filter((subApplicItem: string) => criteriasMap.has(subApplicItem))
      .forEach((subApplicItem: string) => {
        this.subapplicabilities.data[subApplicItem].criterias.forEach((dbItem: string) =>
          Object.keys(this.dbCriterias.data)
            .filter(criteria => criteria === dbItem)
            .forEach((criteria: string) =>
              this._fillCriteriasObject(criteriasObject, this.dbCriterias.data[criteria])
            )
        );
      });
  }

  /**
   * Fill the criteriasObject.
   *
   * @param criteriasObject the applic object to fill.
   * @param criteria the criteria item to use.
   */
  private _fillCriteriasObject(criteriasObject: ApplicObject, criteria: CriteriaItem): void {
    const from = criteria.from;
    const to = criteria.to;

    if (from) {
      criteriasObject.serialNo.push(to === from ? from : `${from}\u2011${to}`); // Non-breaking hyphen.
    }

    if (criteria.name === "version") {
      criteriasObject.versions.push(criteria.value);
    }
  }
}
