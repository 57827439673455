import {
  Component,
  OnInit,
  OnDestroy,
  ChangeDetectorRef,
  AfterViewInit,
  HostBinding
} from "@angular/core";
import { Store, PouchService, ApplicabilityService } from "@viewer/core";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import {
  handleTableScroll,
  addLevelForHierarchy,
  createEndOfDocument
} from "@viewer/content-provider/dom-manipulator";
import { action } from "mobx-angular";
import { autorun, IReactionDisposer, makeObservable, runInAction, reaction } from "mobx";
import { XSLService } from "@viewer/content-provider/xsl.service";
import { concatMap, filter, merge, Subscription, tap } from "rxjs";
import {
  buildSynchroReferential,
  findMetaMedia
} from "@viewer/content-provider/synchro-dom-manipulator";
import { TitleService } from "@viewer/header/title.service";
import {
  createHotspotObject,
  changeHotspotValue,
  createStepObjects,
  getChipAndLevel,
  StepObject,
  getMultimediaInformation,
  MultiMediaInfo,
  getMultimediaObject,
  getProceduralStepObject
} from "@viewer/content-provider/step-dom-manipulator";
import { BasicViewService } from "@viewer/content-provider/basic-view.service";
import { ViewerMessageService } from "@viewer/core/message/viewer.message.service";
import { PreprintService } from "@viewer/core/toc-items/preprint.service";
import xpath from "xpath";
import { Util } from "@orion2/utils/datamodule.utils";
import { NoteService } from "@viewer/core/toc-items/note.service";
import { TocInfo } from "@orion2/models/couch.models";
import { Note, Preprint, DocumentDoc, ServiceBulletin } from "@orion2/models/tocitem.models";
import { DocumentService } from "@viewer/core/toc-items/document.service";
import { ServiceBulletinService } from "@viewer/core/toc-items/service-bulletin.service";
import { SupersededService } from "@viewer/core/superseded/superseded.service";
import { isIos, isSafari, getMediaId, getFileNameFromTocItem } from "@orion2/utils/functions.utils";
import { CiraService } from "@viewer/core/ipc/cira/cira.service";
import { environment } from "@viewer-env/environment";
import { MediaService } from "@viewer/media-module/media/media.service";
import { StatusCodes } from "http-status-codes";
import { getSensitiveMessage } from "@orion2/utils/front.utils";

@Component({
  selector: "o-content-provider",
  templateUrl: "./content-provider.component.html",
  styleUrls: ["./content-provider.component.scss"]
})
export class ContentProviderComponent implements OnInit, OnDestroy, AfterViewInit {
  @HostBinding("class.not-applicable") isNotApplicable = false;

  public isMMA = false;
  public isPreprint = false;
  public isSafari = false;
  public isIos = false;
  public currentDate: Date;
  public pubIdParsed: string;
  public ipcView = false;
  public iframeSrc;
  public multiMediaInfo: MultiMediaInfo[];
  public proceduralStepObject;
  public errorMessage = undefined;
  public isLoading = true;
  public pdfId = "";
  public base: string;
  public url: string;
  public isCordova: boolean;
  public preprintSideBySide: boolean;
  public preprintMinimize: boolean;
  public sensitiveMessage: string;
  public dataDisclosure: string;

  private thumbInContent: xpath.SelectedValue[];
  private smgInContent: xpath.SelectedValue[];
  private loaderAutorun: IReactionDisposer;
  private dmcChangeHandlerReaction: IReactionDisposer;
  private applicReaction: IReactionDisposer;
  private hotspotFromTextReaction: IReactionDisposer;
  private hotspotFromSvgReaction: IReactionDisposer;
  private langReaction: IReactionDisposer;
  private pdfView = false;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private duMetadata: any;

  private subscriptions = new Subscription();

  // provider set the dataObject for distinct view
  constructor(
    public store: Store,
    private route: ActivatedRoute,
    private router: Router,
    private pouchService: PouchService,
    private xslService: XSLService,
    private titleService: TitleService,
    private applicabilityService: ApplicabilityService,
    private cdr: ChangeDetectorRef,
    private basicViewService: BasicViewService,
    private supersededService: SupersededService,
    public messageService: ViewerMessageService,
    public preprintService: PreprintService,
    public documentService: DocumentService,
    public sbService: ServiceBulletinService,
    public noteService: NoteService,
    public ciraService: CiraService,
    public mediaService: MediaService
  ) {
    makeObservable<ContentProviderComponent, "_dmcChangeHandler" | "isProceduralLegacy">(this, {
      ngOnInit: action,
      _dmcChangeHandler: action,
      setDuContent: action,
      isProceduralLegacy: action,
      defineStepMetadata: action
    });
    this.isIos = isIos();
    this.isSafari = this.isIos || isSafari();
    this.isCordova = environment.platform === "cordova";
  }

  /**
   * Set current dmc
   */
  ngOnInit() {
    this.sensitiveMessage = getSensitiveMessage(this.store.pubInfo?.occurrenceCode);

    this.currentDate = new Date();
    // FIX If we declare this reaction after the autorun displayDone is never set to false and highlight is not displayed
    this.store.displayDone = false;
    // Get the gurrent DMC before loading content (need for ipc.component for exemple)
    this.subscriptions.add(
      this.route.paramMap
        .pipe(
          tap((paramMap: ParamMap) => {
            runInAction(() => {
              this.store.currentDMC = paramMap.get("dmc") || "loap";
              this.store.mediaThumbs = [];
              this.store.currentMediaId = undefined;
            });
          }),
          filter(() => this.store.currentDMC !== "loap"),
          concatMap(() =>
            merge(
              this.preprintService.preprintMinimize.pipe(
                tap((minimize: boolean) => {
                  this.preprintMinimize = minimize;
                })
              ),
              this.preprintService.sideBySide.pipe(
                tap((sideBySide: boolean) => {
                  this.preprintSideBySide = sideBySide;
                })
              )
            )
          )
        )
        .subscribe()
    );

    // Compute isNotApplicable when applicable list and filter state change
    this.applicReaction = reaction(
      () => ({
        applicMD5: this.store.applicableMD5,
        isFilteringAvailable: this.store.isFilteringAvailable
      }),
      state => {
        if (state.applicMD5 && state.isFilteringAvailable) {
          this.isNotApplicable = !this.applicabilityService.isApplicable(this.store.currentTocNode);
        }
      },
      { fireImmediately: true }
    );

    this.langReaction = reaction(
      () => this.store.currentLanguage,
      () => this.setDuContent()
    );

    //SPEC: react to a hotspot click from svg or media legend
    // In this case we want to highlight all occurences of the hotspots in text and scroll on first one
    this.hotspotFromSvgReaction = reaction(
      () => this.store.hotspotFromSvg,
      () => {
        const hotspotId = this.store.hotspotFromSvg;
        this.basicViewService.highlightHotspot(hotspotId);
        document
          .querySelector(".marked-text")
          ?.scrollIntoView({ block: "center", behavior: "smooth" });
      }
    );
    //SPEC: react to a hotspot click from text
    // In this case we just want to highlight all occurences of the hotspots in text
    this.hotspotFromTextReaction = reaction(
      () => this.store.hotspotFromText,
      () => {
        const hotspotId = this.store.hotspotFromText;
        this.basicViewService.highlightHotspot(hotspotId);
        //SPEC: emit scroll event for triggering scroll in the legend
        window.scrollTo(window.scrollX, window.scrollY + 1);
        window.scrollTo(window.scrollX, window.scrollY - 1);
      }
    );
  }

  ngAfterViewInit() {
    this.loaderAutorun = autorun(() => {
      if (this.store.displayDone === this.isLoading) {
        this.isLoading = !this.store.displayDone;
        this.cdr.detectChanges();

        // Resets highlighting so that next displayed DM won't be highlighted
        // However, if the user goes back to the search (history.back), this.store.highlightKeyword will
        // be set back this.store.searchInput value and highlight will be active again.
        // BEWARE, if store.displayDone is set too true to soon, ie. before the content is fully loaded or built
        // no highlight will be available.
        // We do a set timeout in order to set in the next tick if store.displayDone is setted to true to quickly
        // in case of /first route this trick doesn't work so we set the highlightKeyword on redirect component
        setTimeout(() => (this.store.highlightKeyword = undefined), 0);
      }
    });

    // _dmcChangeHandler insert content when all the content is loaded
    this.dmcChangeHandlerReaction = reaction(
      () => [this.store.currentDMC, this.store.needReload],
      () => {
        // Reset is Procedural
        this.store.isProcedural = false;
        this.store.isProceduralLegacy = false;
        this.store.currentHotspotId = "";
        this.store.selectedParts = "";
        this.store.selectedConf = undefined;
        this.store.isPlayerActivated =
          this.store.pubInfo?.capabilities?.player3D &&
          this.store.playerSettings[environment.platform] &&
          this.store.userActivate3D;
        // we want to close the note drawer when we change currentDMC
        this.store.noteDrawerOpen = false;
        this.store.applicCondition = [];
        this._dmcChangeHandler().then(() => {
          if (this.store.currentTocNode?.versions && !this.store.isS1000D) {
            this.pubIdParsed = this.store.currentTocNode.versions.join(",") || "-";
          }
        });
      },
      { fireImmediately: true }
    );

    // We subscribe to notes attached to current DU
    // remember that tocItem.service react to a change of
    // dmc in store to retrieve tocItems
    this.subscriptions.add(
      this.noteService.tocItemsForTarget.subscribe((data: Note[]) => {
        if (!data) {
          return this.noteService.refresh();
        }
        this.noteService.sortTocItemList(data);
        this.store.currentNotes = data;
      })
    );
  }

  public scrollTop(): void {
    window.scroll(0, 0);
  }

  /**
   * Remove the dmc subscription
   */
  ngOnDestroy(): void {
    this.store.noteDrawerOpen = false;
    this.loaderAutorun();
    this.dmcChangeHandlerReaction();
    this.applicReaction();
    this.hotspotFromSvgReaction();
    this.hotspotFromTextReaction();
    this.langReaction();
    this.subscriptions.unsubscribe();
  }

  public focusFirstEditNote(): void {
    const input = document.querySelector<HTMLElement>(".from_drawer input");
    input.focus();
  }

  /**
   * Define view
   * ipc : use xsd
   * step by step  check procedural
   * media first : check hasthumbs and no ipc xsd
   */
  public async setDuContent(): Promise<void[]> {
    // Reset duStatus in order to refresh to the new page
    this.store.duStatus = undefined;

    this._setPageTitleFromDU();

    if (this.store.currentDMC.match(/^(preprint|document|sb|superseded)/)) {
      // For superseded data we set the pdfId from the current DMC
      if (this.store.currentDMC.startsWith("superseded")) {
        this.pdfId = this.store.currentDMC.split("__")[1];
      }

      runInAction(() => {
        // For preprint and superseded data we don't want to do the XSLT transformation
        this.store.duObject = {
          dmc: this.store.currentDMC,
          duTitle: this.duMetadata?.title || "",
          duReference: this.duMetadata?.reference || "",
          data: undefined
        };
      });
    } else {
      const xmlNodes = await this.xslService.getXMLNode(this.store.currentDMC);
      // move checkS1000D from transmorHandler() because we want to check for ipc and msm
      this.store.isS1000D = Util.checkS1000D(xmlNodes);
      this.store.isVendors = Util.checkVendors(xmlNodes);

      if (this.store.isS1000D || this.store.isVendors) {
        this.basicViewService.createDmRefHotspotMap(xmlNodes);
      }

      this.getPdfMedias(xmlNodes);
      this.getGraphicEntities(xmlNodes);

      // case ipc : do not need xslt transformation
      this.ipcView = Util.isIpc(xmlNodes);
      this.isMMA = Util.isMMA(xmlNodes);
      this.xslService.ipc = this.ipcView;

      // we need issue infos on title of print files (save as pdf)
      const [issueNumber, inWork] = this._getIssueInfoData(xmlNodes);

      const figureSheetTitles: Map<string, string>[] = await this.mediaService.getFigureSheetTitles(
        xmlNodes,
        !!this.ipcView
      );

      this.store.figureTitles = new Map<string, string>(
        figureSheetTitles.reduce((allSheets, sheets) => {
          sheets.forEach((title, id) => allSheets.set(id, title));
          return allSheets;
        }, new Map())
      );

      const illusIDs: string[][] = this._sortIllustrationIdsWithIndex(figureSheetTitles, 6);

      const data = await this._getTransformedXmlNodesIfNeeded(xmlNodes);

      runInAction(() => {
        this.store.duObject = {
          dmc: this.store.currentDMC,
          // loap does not exist in toc yet
          duTitle: this.duMetadata?.shortTitle || "",
          duReference: this.duMetadata?.reference || "",
          xml: xmlNodes,
          data,
          illusIDs,
          issueNumber,
          inWork
        };
      });

      // Get the value of "dataDisclosure" to display the banner (DRAFT/PREPRINT)
      this.dataDisclosure = xmlNodes.querySelector("dataDisclosure")?.textContent;
    }

    return Promise.all([
      this.getPreprints(),
      this.getDocuments(),
      this.getServiceBulletins(),
      this.getDuInfo()
    ]);
  }

  /**
   * Set stepObject in store
   *
   * @param frag
   * @param xmlNodes
   */
  public defineStepMetadata(frag: Element, xmlNodes: Element): void {
    if (!this.store.isProcedural) {
      // We have to set stepObjects to [] here otherwise this.store.stepObjects still contains steps
      // of previous DM and they will be displayed in current DM which we know is not a procedure.
      // Worse, as store.isProcedural is based on this.store.stepObjects.length > 0
      // this means that isProcedural could be erroneously seen has true.
      runInAction(() => {
        this.store.stepObjects = [];
      });
      return;
    }

    // H160
    const mainFigureIdTag = xmlNodes.querySelector("mainProcedure > figure > graphic");

    // Check Media if 3D
    this.multiMediaInfo = undefined;
    const multiMediaObject = getMultimediaObject(xmlNodes);
    this.proceduralStepObject = getProceduralStepObject(xmlNodes);
    if (this.store.isS1000D) {
      this.store.mediaType = multiMediaObject
        ? multiMediaObject.getAttribute("multimediaType")
        : false;
    }
    this.store.mainFigureId = mainFigureIdTag
      ? mainFigureIdTag.getAttribute("infoEntityIdent")
      : "";

    if (multiMediaObject) {
      // Get Multimedia information for create step and hotspot object
      this.multiMediaInfo = getMultimediaInformation(multiMediaObject);

      this.store.hotspotObjects = createHotspotObject(xmlNodes, this.multiMediaInfo);
      // Change hotspot html in dom
      changeHotspotValue(frag, this.multiMediaInfo);
    }
    // Create Step & Hotspot Object for StepByStep View
    runInAction(() => {
      this.store.stepObjects = this.createStepsObjects(frag, xmlNodes);
      this.store.stepMetaMedia = findMetaMedia(xmlNodes);
    });
    this.store.synchronizationInformation = buildSynchroReferential(
      this.proceduralStepObject,
      multiMediaObject
    );
  }

  /**
   * Set the title of the page from the DU.
   */
  private _setPageTitleFromDU(): void {
    if (this.store.currentDMC !== "loap") {
      this.duMetadata = this.store.currentTocNode;
      this.titleService.setPageTitleFromDU(this.duMetadata);
    } else {
      this.titleService.setPageTitleFromDU(undefined);
    }
  }

  /**
   * Updates the thumbInContent and smgInContent variables with the values from
   * the xml nodes.
   *
   * @param xmlNodes the xml nodes to use.
   */
  private getGraphicEntities(xmlNodes: Document): void {
    this.thumbInContent = xpath.select(
      "/dmodule/ENTITE_GRAPHIQUE/FORMAT[text()!='smg']/../NOM_LOGIQUE",
      xmlNodes
    );
    this.smgInContent = xpath.select(
      "/dmodule/ENTITE_GRAPHIQUE/FORMAT[text()='smg']/../NOM_LOGIQUE",
      xmlNodes
    );
  }

  /**
   * Gets some attributes from the issueInfo in the xml nodes.
   *
   * @param xmlNodes the xml nodes to use.
   * @returns the values of the issue number and the in work attributes.
   */
  private _getIssueInfoData(xmlNodes: Document): string[] {
    const issueInfo = xmlNodes.querySelector("issueInfo");
    const issueNumber = issueInfo?.getAttribute("issueNumber") || "";
    const inWork = issueInfo?.getAttribute("inWork") || "";
    return [issueNumber, inWork];
  }

  /**
   * For IPC DM S1000D. We want to sort the illustration IDs.
   *
   * @param figureSheetTitles the list of figures attached to the duObject.
   * @param index the nth element to use for the sorting after the split.
   * @returns the sorted figureSheetTitles.
   */
  private _sortIllustrationIdsWithIndex(
    figureSheetTitles: Map<string, string>[],
    index: number
  ): string[][] {
    return this.ipcView && this.store.isS1000D
      ? figureSheetTitles.map(figureSheets =>
          [...figureSheets.keys()].sort((illusA: string, illusB: string) =>
            illusA.split("-")[index].localeCompare(illusB.split("-")[index])
          )
        )
      : figureSheetTitles.map(figureSheets => [...figureSheets.keys()]);
  }

  /**
   * Get back the xml nodes as is if in IPC or PDF view.
   * Otherwise, get back the transformed xml nodes.
   *
   * @param xmlNodes the xml nodes to potentially transform.
   * @returns the xmlNodes or the transformed xmlNodes.
   */
  private async _getTransformedXmlNodesIfNeeded(xmlNodes: Document): Promise<Document | Element> {
    return this.ipcView || this.pdfView ? xmlNodes : await this.transformHandler(xmlNodes);
  }

  /** Replace img source for special char */
  private replaceImgSrc(element: HTMLElement): void {
    let src = element.getAttribute("src");
    // Deleting the srcs of images, to avoid loading images on a server that does not exist.
    // This reduces loading time.
    if (src.includes("./graphics/thumbnails") || src.includes("icons/")) {
      element.removeAttribute("src");
    } else {
      src = src.replace("resources/images", "assets");
      element.setAttribute("src", src);
    }
  }

  private _dmcChangeHandler(): Promise<void> {
    // Reset view informations
    this.resetViewInfos();
    this.store.displayDone = false;

    return this.setCurrentTocNode()
      .then(() => {
        this.isNotApplicable =
          this.store.currentTocNode &&
          !this.applicabilityService.isApplicable(this.store.currentTocNode);
        this.errorMessage = undefined;
        this.duMetadata = undefined;
        return this.setDuContent();
      })
      .then(() => {
        if (!this.store.isProcedural) {
          runInAction(() => {
            // SPEC: We need to reset "stepMetaMedia" in case we back from stepbystep view to a fullscreen illustration
            this.store.stepMetaMedia = "";
          });
        }
        if (this.route.firstChild) {
          this.subscriptions.add(
            this.route.firstChild?.firstChild?.firstChild?.url.subscribe(url => {
              runInAction(() => {
                this.store.view = this.isPreprint ? "preprint_fullscreen" : url[0].path;
              });
            })
          );
          return Promise.resolve();
        }
        return this._defineRedirectView()
          .then((route: string[]) => this._goToView(route))
          .then(() => {});
      })
      .catch(error => {
        if (
          error.status === StatusCodes.NOT_FOUND ||
          error.status === StatusCodes.INTERNAL_SERVER_ERROR
        ) {
          this.errorMessage = error.message;
          this.store.displayDone = true;
          runInAction(() => {
            this.store.duObject = undefined;
          });
        }
      });
  }

  private setCurrentTocNode(): Promise<void> {
    let tocNodePromise;
    if (
      this.store.currentDMC.startsWith("preprint") ||
      this.store.currentDMC.startsWith("document") ||
      this.store.currentDMC.startsWith("sb")
    ) {
      tocNodePromise = this.pouchService.tocPublicCaller.get(this.store.currentDMC);
    } else if (this.store.currentDMC.startsWith("superseded")) {
      this.pdfView = true;
      tocNodePromise = this.supersededService.createTocNode(this.store.currentDMC);
    } else {
      const dmc = this.store.currentDMC === "loap" ? "root" : this.store.currentDMC;
      tocNodePromise = this.pouchService.tocCaller.get(dmc);
    }

    return tocNodePromise.then((currentTocNode: TocInfo) => {
      runInAction(() => {
        this.store.currentTocNode = currentTocNode;
      });
    });
  }

  /**
   * Set all view informations to false
   * View informations are used to redirect to the correct view
   */
  private resetViewInfos(): void {
    this.ipcView = false;
    this.pdfView = false;
  }

  /**
   * Get pubMediaCode if pubMediaType is PDF
   *
   * @param xmlNodes
   */
  private getPdfMedias(xmlNodes: Document) {
    const pdfMedias = xmlNodes.querySelectorAll("pubMedia");
    const pdfMediaId = [];
    for (const pdf of Array.from(pdfMedias)) {
      if (pdf.getAttribute("pubMediaType") === "PDF") {
        this.pdfView = true;

        pdfMediaId.push(pdf.getAttribute("pubMediaCode"));
      } else {
        this.pdfView = false;
      }
    }
    this.pdfId = pdfMediaId[0];
  }

  private transformHandler(xmlNodes: Document): Promise<Element> {
    return this.xslService
      .doXSLTTransform(this.store.currentDMC, xmlNodes, this.store.isVendors)
      .then((frag: Element) => {
        this.store.isProcedural = this.isProcedural(frag, xmlNodes);
        handleTableScroll(frag);
        if (this.store.currentDMC !== "loap") {
          this.hydrateXML(frag, xmlNodes);
        }
        Array.from(frag.querySelectorAll("img")).forEach((element: HTMLElement) =>
          this.replaceImgSrc(element)
        );
        return frag;
      });
  }

  /**
   * Define if media first or fulltext
   *
   * @param xml
   */
  private _defineRedirectView(): Promise<string[]> {
    let route = ["fulltext", "default"];

    if (this.ipcView) {
      const idMedia3D = (this.smgInContent[0] as Element)?.innerHTML;
      const idMedia2D = (this.thumbInContent[0] as Element).innerHTML;
      return this.check3dIsFound(idMedia3D).then((found: boolean) =>
        found ? ["ipc", idMedia3D] : ["ipc", idMedia2D]
      );
    }
    if (this.pdfView && !this.isPreprint) {
      route = ["pdf", this.pdfId];
    }

    if (this.store.duObject.xml && this.xslService.hasOnlyGraphics(this.store.duObject.xml)) {
      runInAction(() => {
        this.store.view = "fullscreen";
      });
      route = ["fulltext", "fullscreen", (this.thumbInContent[0] as Element).innerHTML];
    }

    return Promise.resolve(route);
  }

  private check3dIsFound(smgId: string): Promise<boolean> {
    if (!this.store.isPlayerActivated || !smgId) {
      return Promise.resolve(false);
    }
    const mediaId = getMediaId(this.store.isLegacyImport, this.store.currentDMC, smgId);
    return this.mediaService.getBlob(mediaId).then((media: Blob) => {
      // We deactivate player if smg is not found
      this.store.isPlayerActivated = !!media;
      return !!media;
    });
  }

  private hydrateXML(frag, xmlNodes): void {
    this.defineStepMetadata(frag, xmlNodes);
    if (Util.isMSM(xmlNodes)) {
      if (!this.store.isS1000D) {
        this.basicViewService.addInitialColumn(frag);
      }
      this.basicViewService.createSignatureTable(frag);
    }
    frag.appendChild(createEndOfDocument());
    addLevelForHierarchy(frag);
  }

  private _goToView(route: string[]): Promise<boolean> {
    return this.router.navigate(route, {
      relativeTo: this.route.root.firstChild.firstChild.firstChild,
      queryParamsHandling: "preserve",
      replaceUrl: true
    });
  }

  /**
   * Return true if one the section contains "procedure"
   * Later, if 'defineStepMetadata' and 'createStepsObjects' works with legacy procedures,
   * remove all 'isProceduralLegacy' references to keep original isProcedural
   *
   * @param xmlNodes
   */
  private isProceduralLegacy(xmlNodes: Document): boolean {
    const manuel = xmlNodes.querySelector("manualLabel");
    const isAMM = manuel?.textContent.includes("AMM");
    const legacyTitles = xmlNodes.querySelectorAll("TOPIC TITLE");
    return Array.from(legacyTitles).some((title: Element) => {
      const includesProc = /proc(e|é)dure|verfahren/i.test(title.textContent);
      const subTasks = title.parentNode.querySelectorAll("SUBTASK");
      return isAMM && includesProc && subTasks.length > 0;
    });
  }

  /**
   * set metadata for step
   * xml node to find step
   *
   * @param frag
   * @param xmlNodes
   */
  private createStepsObjects(frag: Element, xmlNodes: Element): StepObject[] {
    const steps = frag.querySelectorAll(".AHX4_card_procedural_step");
    if (steps && steps.length === 0) {
      return [];
    }
    const stepObjects = createStepObjects(
      xmlNodes,
      this.store.isLegacyImport,
      this.store.currentDMC,
      this.multiMediaInfo,
      this.proceduralStepObject
    );

    const dmSteps = Array.from(steps).map((step: Element, index: number) => {
      const chip = getChipAndLevel(step);
      if (stepObjects[index].id === "firstLevel") {
        // SPEC: in this case we set a custom id to prevent to have many steps with same id
        // transform chip (1.3.) to customId (stp-custom-13)
        stepObjects[index].id = "stp-custom-" + chip.label.replace(/\./g, "");
      }
      return {
        ...stepObjects[index],
        chip: chip.label,
        level: chip.level
      };
    });

    return [this.getSpecialStep("prepa"), ...dmSteps, this.getSpecialStep("close")];
  }

  /** Returns the step object for preparation or close-up steps */
  private getSpecialStep(step: "prepa" | "close"): StepObject {
    return {
      id: step,
      chip: "",
      level: 1,
      illusId: this.store.mainFigureId
    } as StepObject;
  }

  /**
   * return true if step is found or if is procedural legacy
   *
   * @param frag
   * @param xmlnodes
   */
  private isProcedural(frag: Element, xmlnodes: Document): boolean {
    this.store.isProceduralLegacy = this.isProceduralLegacy(xmlnodes);
    const steps = frag.querySelectorAll(".AHX4_card_procedural_step");
    return !this.store.isVendors && (steps.length > 0 || this.store.isProceduralLegacy);
  }

  private getDuInfo(): Promise<void> {
    const duStatusDiv = ".duStatusDiv";
    // For IPC and S1000D MSM we don't use the stylesheet we need to generate the du info
    // We can't generate duStatus for toc-items DM like preprint, etc...
    return this.store.duStatus || !this.store.duObject?.xml
      ? Promise.resolve()
      : // globalThis is the global namespace
        // see: https://developer.mozilla.org/fr/docs/Web/JavaScript/Reference/Objets_globaux/globalThis
        this.xslService.duInfo.then((html: globalThis.Document) => {
          const applicTableValue = html.querySelector(".applicability_serial_numbers_value");
          if (this.store.isS1000D) {
            // in S1000D case we want to display applicability serial numbers with conditions in infoDU
            // is not possible to add conditionnal applicabilities with xsl
            // so we replace them after the xsl transformation
            this.ciraService
              .getConditionalApplic(this.store.currentTocNode.applicabilityMD5)
              .then((applicCondition: string[]) => {
                this.store.applicCondition = applicCondition;
                if (applicTableValue) {
                  applicTableValue.innerHTML = "";
                  this.store.applicCondition?.forEach((condition: string, index: number) => {
                    const div = document.createElement("div");
                    div.textContent = index ? `OR ${condition}` : condition;
                    applicTableValue.appendChild(div);
                  });
                }
                runInAction(() => {
                  this.store.duStatus = html.querySelector(duStatusDiv);
                });
                // We want to replace the duStatusDiv in duObject data too
                // for have right applic value in print
                const xslTransformatedDM = !this.ipcView && !this.pdfView;
                if (xslTransformatedDM) {
                  this.store.duObject.data
                    .querySelector(duStatusDiv)
                    ?.replaceWith(this.store.duStatus);
                }
              });
          } else {
            runInAction(() => {
              this.store.duStatus = html.querySelector(duStatusDiv);
            });
          }
        });
  }

  private getPreprints(): Promise<void> {
    return this.preprintService.getPreprint(this.store.currentDMC).then((preprint: Preprint) => {
      this.isPreprint = Boolean(preprint);

      // Override title and subtitle with preprint data
      if (this.isPreprint) {
        this.titleService.setPageTitle(preprint.title, preprint.reference);
      }
      runInAction(() => {
        this.store.view = this.isPreprint ? "preprint_fullscreen" : "default";
      });
    });
  }

  private getDocuments(): Promise<void> {
    return this.documentService
      .getAllDocument(this.store.currentDMC)
      .then((document: DocumentDoc) => {
        // Override title and subtitle with document data
        if (document) {
          this.pdfView = true;
          this.pdfId = getFileNameFromTocItem(document);
        }
      });
  }

  private getServiceBulletins(): Promise<void> {
    return this.sbService
      .getAllServiceBulletin(this.store.currentDMC)
      .then((sb: ServiceBulletin) => {
        // Override title and subtitle with sb data
        if (sb) {
          this.pdfView = true;
          this.pdfId = getFileNameFromTocItem(sb);
        }
      });
  }
}
