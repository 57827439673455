import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ServiceBulletin } from "@orion2/models/tocitem.models";
import { ApplicabilityService, Store } from "@viewer/core";
import { CiraService } from "@viewer/core/ipc/cira/cira.service";
import { SearchResult } from "@viewer/core/search/searchModel";
import { ServiceBulletinService } from "@viewer/core/toc-items/service-bulletin.service";
import { IReactionDisposer, reaction } from "mobx";

export interface OrionCardData {
  title: string;
  dmc?: string;
  subtitle?: string;
  revisionMark?: string;
  versions?: string[];
  applicabilityMD5?: string;
  footer?: OrionCardFooter;
  showAdditionalInfos?: boolean;
  forceVisibility?: boolean;
  forceTitle?: boolean;
  forceSubTitle?: boolean;
}

export interface OrionCardFooter {
  key: string;
  parameters?: Object;
}

@Component({
  selector: "o-orion-card",
  templateUrl: "./orion-card.component.html",
  styleUrls: ["./orion-card.component.scss"]
})
export class OrionCardComponent implements OnInit, OnDestroy {
  @Input() data: OrionCardData;

  public searchData: SearchResult;
  public title: string;
  public revisionMark: string;
  public versions: string[];
  public isNotApplicable = false;
  public applicabilities: string[] = undefined;
  public applicabilityOpen = false;
  public isImpactedDM = false;

  private applicFilterReaction: IReactionDisposer;
  private searchReaction: IReactionDisposer;

  constructor(
    public store: Store,
    private applicabilityService: ApplicabilityService,
    private ciraService: CiraService,
    private sbService: ServiceBulletinService
  ) {}

  ngOnInit(): void {
    this.title = this.data.title;
    this.revisionMark = this.data.revisionMark;
    this.versions = this.data.versions;

    this.setApplicFilterReaction();

    if (this.data.showAdditionalInfos && this.data.applicabilityMD5) {
      this.getCiraApplics(this.data.applicabilityMD5);
    }

    if (!this.data.dmc) {
      return;
    }

    if (this.data.showAdditionalInfos) {
      this.checkIsImpactingSB();
    }

    this.searchReaction = reaction(
      () => this.store.offlineSearchReady,
      () => {
        if (this.store.offlineSearchReady) {
          this.searchData = this.store.getDUMeta(this.data.dmc);

          if (!this.searchData) {
            return;
          }

          // Ex. For bookmarks we never want to change the passed title
          if (!this.data.forceTitle) {
            this.title = this.searchData.shortTitle;
          }

          this.versions = this.searchData.versions;
          this.revisionMark = this.searchData.attachedPreprint
            ? "preprint"
            : this.searchData.type || this.searchData.revision;

          this.isNotApplicable = !this.applicabilityService.isApplicable(this.searchData);

          // We don't have applicabilityMD5 on history elements, so we must wait for searchReady.
          if (
            this.data.showAdditionalInfos &&
            !this.data.applicabilityMD5 &&
            this.searchData.applicabilityMD5
          ) {
            this.getCiraApplics(this.searchData.applicabilityMD5);
          }
        }
      },
      { fireImmediately: true }
    );
  }

  ngOnDestroy(): void {
    if (this.searchReaction) {
      this.searchReaction();
    }

    if (this.applicFilterReaction) {
      this.applicFilterReaction();
    }
  }

  public toggleApplic(event: Event) {
    event.preventDefault();
    event.stopImmediatePropagation();
    this.applicabilityOpen = !this.applicabilityOpen;
  }

  private setApplicFilterReaction() {
    this.applicFilterReaction = reaction(
      () => ({
        applicMd5: this.store.applicableMD5,
        show: this.store.showNotApplicable,
        isFilteringAvailable: this.store.isFilteringAvailable
      }),
      () => {
        const data = this.searchData || this.data;
        this.isNotApplicable = !this.applicabilityService.isApplicable(data);
      },
      { fireImmediately: true }
    );
  }

  private getCiraApplics(applicabilityMD5: string): Promise<void> {
    return this.ciraService
      .getConditionalApplic(applicabilityMD5)
      .then((applicabilities: string[]) => {
        this.applicabilities = applicabilities;
      });
  }

  private checkIsImpactingSB() {
    this.sbService.getAllImpactingSB(this.data.dmc).then((sbs: ServiceBulletin[]) => {
      this.isImpactedDM = sbs.length > 0;
    });
  }
}
